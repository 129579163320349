<template>
	<div>
		<h3 class="mb-4">
			{{ translate('refunds') }}
			<button
				v-if="isRefundable && $can('orders', 'refund')"
				class="btn ml-2  btn-primary float-right"
				@click="$router.push({ name: 'NewRefund'})">
				{{ translate('new_refund') }}
			</button>
		</h3>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th
							class="border-top-0"
							style="width:5px">
							#
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0">
							{{ translate('transaction_id') }}
						</th>
						<th class="border-top-0 width-200">
							{{ translate('reason') }}
						</th>
						<th class="border-top-0 width-200 text-right">
							{{ translate('refunded_amount') }}
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0 width-200">
							{{ translate('refunded_by') }}
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0 width-200">
							{{ translate('notes') }}
						</th>
						<th class="border-top-0 width-200">
							{{ translate('date') }}
						</th>
						<th class="border-top-0 width-200 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td
							class="align-middle"
							style="width:5px">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							{{ item.attributes.transaction_id }}
						</td>
						<td class="align-middle text-left">
							{{ item.attributes.reason_code_name ? translate(item.attributes.reason_code_name) : '' }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.refund_amount }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							{{ item.attributes.refunded_by }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle text-left">
							{{ item.attributes.notes ? item.attributes.notes : '' }}
						</td>
						<td class="align-middle">
							{{ $moment(item.attributes.date.date).format(dateFormat) }}
						</td>
						<td class="align-middle text-center">
							<b-button
								v-b-tooltip.hover
								:title="translate('download')"
								:disabled="loading"
								class="btn mx-1 bg-primary-alt"
								@click="$emit('download-refund', item.id, item.attributes.order_id)">
								<i
									class="fa fa-download"
									aria-hidden="true" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('no_records_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>

<script>
import {
	OrdersDetails, Products, PurchasePayment, PurchaseShipping, Grids,
} from '@/translations';
import { YMDHMS_FORMAT } from '@/settings/Dates';
import { PAGINATION } from '@/settings/RequestReply';
import { admin } from '@/settings/Roles';

export default {
	messages: [OrdersDetails, Products, PurchasePayment, PurchaseShipping, Grids],
	props: {
		data: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			required: true,
		},
		hasData: {
			type: Boolean,
			required: true,
		},
		pagination: {
			type: Object,
			default() {
				return {};
			},
		},
		isRefundable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dateFormat: YMDHMS_FORMAT,
			admin,
		};
	},
	methods: {
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			if (this.$can('orders', 'refund')) this.$emit('on-change', options);
		},
	},
};
</script>
<style>
.width-200{
		width: 200px;
}
</style>
