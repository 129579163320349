<template>
	<div
		v-if="value"
		class="image-viewer"
		@wheel="handleWheel">
		<div style="position: relative; height: 100%; display: flex; justify-content: center; align-items: center">
			<div
				:style="{ transform: `scale(${scale})`, transition: 'transform 0.2s' }"
				@mousedown="handleMouseDown"
				@mouseup="handleMouseUp"
				@mousemove="handleMouseMove"
				@mouseleave="handleMouseLeave">
				<img
					:src="imageSrc"
					alt="Zoomable image">
			</div>
			<div
				class="toolbar"
				style="position: absolute; bottom: 5px; left: 0; right: 0">
				<div
					class="d-flex justify-content-center align-items-center"
					style="gap: 5px">
					<button
						v-b-tooltip.hover
						:title="translate('zoom_out')"
						class="btn btn-secondary"
						@click.stop="zoomOut">
						<i class="fas fa-search-minus" />
					</button>
					<button
						v-b-tooltip.hover
						:title="translate('zoom_in')"
						class="btn btn-secondary"
						@click.stop="zoomIn">
						<i class="fas fa-search-plus" />
					</button>
					<button
						v-b-tooltip.hover
						:title="translate('zoom_reset')"
						class="btn btn-secondary"
						@click.stop="resetZoom">
						<i class="fas fa-sync" />
					</button>
					<button
						v-b-tooltip.hover
						:title="translate('center_image')"
						class="btn btn-secondary"
						@click.stop="centerImage">
						<i class="fas fa-crosshairs" />
					</button>
					<button
						v-b-tooltip.hover
						:title="translate('exit_viewer')"
						class="btn btn-secondary"
						@click.stop="exit">
						<i class="fas fa-times" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ImageViewer',
	props: {
		imageSrc: {
			type: String,
			required: true,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			scale: 1,
			dragging: false,
			startX: 0,
			startY: 0,
		};
	},
	methods: {
		zoomIn() {
			if (this.scale < 5) { // Limita el zoom máximo a 5
				this.scale += 0.2;
			}
		},
		zoomOut() {
			if (this.scale > 0.2) { // Limita el zoom mínimo a 0.2 para evitar que la imagen se dé vuelta
				this.scale -= 0.2;
			}
		},
		handleMouseDown(e) {
			e.preventDefault();
			this.dragging = true;
			this.startX = e.x;
			this.startY = e.y;
		},
		handleMouseMove(e) {
			e.preventDefault();
			if (!this.dragging) return;
			const dx = e.x - this.startX;
			const dy = e.y - this.startY;
			const el = e.target;
			el.style.transform = `translate(${dx}px, ${dy}px)`;
		},
		handleMouseUp(e) {
			e.preventDefault();
			this.dragging = false;
		},
		handleMouseLeave(e) {
			e.preventDefault();
			this.dragging = false;
		},
		resetZoom() {
			this.scale = 1;
		},
		centerImage() {
			this.$el.querySelector('img').style.transform = 'translate(0, 0)';
		},
		handleWheel(event) {
			if (event.deltaY < 0) {
				this.zoomIn();
			} else {
				this.zoomOut();
			}
		},
		exit() {
			this.$emit('input', false);
		},
	},
};
</script>

<style scoped>
.image-viewer {
	z-index: 10000;
	position: fixed;
	top: 0;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}
.tooltip {
	z-index: 11000 !important;
}
</style>
